<template>
<div>
    
  <log-table
    @refresh="getLogs"
    :environment="envName"
    :headers="headers"
    :logs="logs"
    :name="name"
    :version="version"
  />
  </div>
</template>

<script>
import { last } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'gameLogs',
  components: {
    logTable: () => import('@/components/logTable.vue'),
  },
  props: {
    envName: {
      type: String,
      default: '',
      required: true,
    },
    image: {
      type: String,
      default: '',
      required: true,
    },
    version: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({     
    headers: [
      {
        text: 'Date',
        value: 'datetime',
      },
      {
        text: 'Correlation',
        value: 'correlation_uid',
      },
      {
        text: 'Severity',
        value: 'severity',
      },
      {
        text: 'Application',
        value: 'application',
      },
      {
        text: 'Message',
        value: 'message',
      },
    ],
    logs: []
  }),
  computed: {
    filteredItems() {
      return this.logs.filter((i) => {
        return !this.severity || (i.severity in this.severity);
      })
    },
    name() {
      return last(this.image.split('_'));
    },
  },
  created() {
    this.getLogs();
  },
  methods: {
    ...mapActions({
      fetchGameOrCoreLogs: 'fetch/fetchGameOrCoreLogs',
    }),
    getLogs() {
      this.fetchGameOrCoreLogs({
        url: `/v2/log/game/${this.envName}`,
        params: {
          image: this.image,
          backend_version: this.version,
        },
      }).then((result) => { this.logs = result; });
    },

  },
};
</script>
